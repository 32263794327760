<template>
  <div>
    <util-date-type-picker />
    <util-date-range-picker />

    <div class="cui__utils__heading">
      <strong>Sending Category</strong>
    </div>
    <div class="card">
      <div class="card-body">
        <chart-sending-category :chartHeight=150 />
      </div>
    </div>

  <div class="cui__utils__heading">
      <strong>Category Drill down</strong>
  </div>
  <table-sending-category-drill-down />

  </div>
</template>

<script>
import DateTypePicker from './DateTypePicker'
import DateRangePicker from './DateRangePickerHelper'
import SendingCategoryChart from './SendingCategoryChart'
import SendingCategoryDrillDown from './SendingCategoryDrillDown'
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  name: 'SendingCategory',
  components: {
    'util-date-type-picker': DateTypePicker,
    'util-date-range-picker': DateRangePicker,
    'chart-sending-category': SendingCategoryChart,
    'table-sending-category-drill-down': SendingCategoryDrillDown,
  },

  computed: {
    ...mapState('admin', [
      'globalDateRange',
      'globalClientSelected',
      'globalDateTypeSelected',
    ]),
  },

  methods: {
    ...mapActions('admin', [
      'fetchOrderedProductDetails',
    ]),
    ...mapMutations('admin', [
      'startLoading',
      'stopLoading',
    ]),

    triggerUpdateChart() {
      this.startLoading()
      this.fetchOrderedProductDetails({
        saveToStateObj: 'setOrderedProductsDetails',
        selectFilter: '(count,total,hits.(data.(product_items.(product_id,product_name,quantity,c_productCategory))))',
        dateType: this.globalDateTypeSelected,
        fromDate: this.globalDateRange.fromDate,
        toDate: this.globalDateRange.toDate,
      })
        .then(() => this.stopLoading())
    },
  },

  mounted() {
    this.triggerUpdateChart()
  },

  watch: {
    globalClientSelected() {
      this.triggerUpdateChart()
    }
  },
}
</script>
